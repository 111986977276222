<template>
    <div class="msgAlert">
        <div id="containers" ref="mapContainers" style="width:100%;height:70vh;"></div>
        <FloatingPanel>
            <el-table height="100%" :data="tableData" border stripe size="medium" style="width: 100%">
                <!-- <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column> -->
                <el-table-column align="center" prop="T_t" label="温度">
                </el-table-column>
                <el-table-column align="center" prop="T_rh" label="湿度">
                </el-table-column>
                <el-table-column align="center" prop="T_time" label="时间" min-width="160">
                </el-table-column>
            </el-table>
        </FloatingPanel>
    </div>
</template>

<script>
import FloatingPanel from "@/components/FloatingPanel.vue";
import { getdevDataNot } from '@/api/instrument/Theorder.js'
import { aesEncrypt } from "@/plugins/aes.js";
export default {
    name: "",
    components: { FloatingPanel },
    data() {
        return {
            activeName: 'first',
            tableData: [],
            path: []
        }
    },
    mounted() {
        this._getDatas()
    },
    methods: {
        async _getDatas() {
            let param = this.$route.query;
            const aes = await aesEncrypt(param.id)

            const { data: result } = await getdevDataNot({ order: aes })
            let arr = result.Data || []
            this.tableData = arr

            // 从 arr 数组生成 path 数组
            this.path = arr.map(item => {
                // 将 T_site 字符串按逗号分割，并转换为数字
                return item.T_site.split(',').map(Number);
            });
            if (window.AMap) {
                this.initMap();
            } else {
                // 可以添加一个定时器或者监听事件来确保 API 加载完成
                window.addEventListener('load', () => {
                    if (window.AMap) {
                        this.initMap();
                    }
                });
            }
        },
        initMap() {
            // 初始化地图
            this.map = new AMap.Map('containers', {
                zoom: 13,
                resizeEnable: true,
                center: this.path[this.path.length - 1],
            });
            this.map.on('error', function() {
               alert('地图加载失败')
            });
            // 绘制运动轨迹
            this.polyline = new AMap.Polyline({
                path: this.path,
                strokeColor: '#FF33FF', // 线颜色
                strokeWeight: 5, // 线宽
                strokeOpacity: 1, // 透明度
                lineJoin: 'round', // 折线拐点连接处样式
                lineCap: 'round', // 折线两端线帽样式
                zIndex: 50
            });
            this.polyline.setMap(this.map);


            // 添加终点标记
            this.endMarker = new AMap.Marker({
                position: this.path[this.path.length - 1],
                icon: 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
                offset: new AMap.Pixel(-10, -34)
            });
            this.endMarker.setMap(this.map);
        },
    },

}
</script>
<style lang="scss">
.msgAlert {
    width: 100%;
    height: 100vh;
    background: #fff;
    color: #000;
    background: rgb(248, 249, 250);
}
</style>