const CryptoJS = require('crypto-js');

export function aesEncrypt(keys) {
    return new Promise((resolve) => {
        const encryptedBase64 = decodeURIComponent(keys);
        const key = CryptoJS.enc.Utf8.parse('xJy1C2Eo5N7qR8Vr'); // 16 字节密钥
        const iv = CryptoJS.enc.Utf8.parse('xJy1C2Eo5N7qR8Vr');  // 16 字节偏移量
        const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedBase64);
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        if (decryptedText) {
            resolve(decryptedText);
        }else{
            alert('参数有误,解密失败');
        }
    })
    
}