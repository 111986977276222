<template>
    <div class="floating-panel">
        <div class="floating-panel-header" @click="_onclick">
        </div>
        <div class="floating-panel-content" :style="{ height: height + 'vh' }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FloatingPanel',
    data() {
        return {
            maxHeight:80,//最大高度为80vh
            minHeight:30,//最小高度为10vh

            height:30,//按下的时候的位置
            diifY:0,//移动的距离
            speed:1
        };
    },
    methods: {
        _onclick(event) {
            this.height = this.height==this.minHeight?this.maxHeight:this.minHeight;
        },
    }
};
</script>

<style scoped>
.floating-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px 30px 0 0 ;
    z-index: 1000;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(248, 249, 250, 0.8);
}

.floating-panel-header {
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #EBEEF5
}
.floating-panel-header::before{
    content: "";
    display: block;
    width: 30px;
    height: 4px;
    background: #ccc;
    margin: 0 auto;
    border-radius: 20px;
}

.floating-panel-content {
    padding: 16px;
    transition: height .2s ease;
}
</style>